<template>
    <div>
        <div class="mb-6 vx-row">
            <vs-button @click="handleBack()" class="mt-2 ml-4" color="danger" icon-pack="feather"
                icon="icon-arrow-left">Back</vs-button>
        </div>
        <table width="100%" class="vs-table vs-table--tbody-table ">
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    File Name
                </td>   
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.data.FileName }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Status
                </td>   
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.data.Status }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Process Started
                </td>   
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.data.StartProcessAt }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Process Ended
                </td>   
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.data.EndProcessAt }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Download
                </td>   
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' }}
                    <a v-if="this.data.Url" :href="this.data.Url">Download File</a>
                    <span v-else >No Download Link</span>
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Remark
                </td>   
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.data.Remark }}
                </td>
            </tr>
        </table>
        
        <table width="100%" class="vs-table vs-table--tbody-table print">
            <thead class="vs-table--thead">
                <tr>
                    <th width="40%">
                        <div>Data</div>
                    </th>
                    <th width="5%">
                        <div>Status</div>
                    </th>
                    <th width="55%">
                        <div>Remark</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in data.LogImportLines" :key="index" style="padding-bottom;: 5px">
                    <td class="td vs-table--td" style="padding: 5px">
                        <pre><code>{{ item.Data }}</code></pre>
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.Status }}
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        {{ item.Remark }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from "moment";
export default {
    components: {
    },
    mounted() {
        this.id = this.$route.params.id;
        this.mode = this.$route.params.mode;
        this.initData();
    },
    data() {
        return {
            dialog: {
                save: false,
            },
            id: null,
            attachment: null,
            data: {},
        }
    },
    methods: {
        async handlePrint() {
            await window.print();
        },
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: this.$store.state.purchaseOrder.baseRouterName,
            });
            this.$vs.loading.close();
        },
        initData() {
            this.$http
                .get(`/api/wms/v1/log/import/${this.id}`)
                .then((resp) => {
                    console.log(resp)
                    if (resp.code == 200) {
                        this.data = resp.data;
                        this.$vs.loading.close();
                    } else {
                    }
                });
        },
    }
}
</script>
<style>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: A4 landscape;
}

/* Set the font size to 12px for printing */
@media print {
    body {
        font-size: 12px;
        background: none !important;
    }

    * {
        background: none !important;
    }

    header,
    footer,
    .print-button,
    #content-area * :not(.content-wrapper *) {
        display: none !important;
        visibility: hidden !important;
    }

    .router-view,
    .router-content {
        padding: 0 !important;
        margin: 0 !important;
    }
}

table.print {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

table.print td,
table.print th {
    border: 1px solid #abab !important;
    text-align: left;
    padding: 8px;
}
</style>
